import { useDownloadFile } from 'api/basePlayInApi'
import { getStockStats, useGetStockById } from 'api/stock'
import { getStockEntriesTransfered, getStockTransfersQueryKey } from 'api/stockEntry'
import fourDoodleSvg from 'assets/svg/icon/doodle_four.svg'
import oneDoodleSvg from 'assets/svg/icon/doodle_one.svg'
import threeDoodleSvg from 'assets/svg/icon/doodle_three.svg'
import twoDoodleSvg from 'assets/svg/icon/doodle_two.svg'
import BottomMessage from 'components/BottomMessage/BottomMesssage'
import Button from 'components/Button/Button'
import { RowContainer } from 'components/Layouts/BlockLayout/BlockLayout.style'
import { PageLoader } from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import Select, { Option } from 'components/Select/Select'
import StatusBlock, { StatusTypes } from 'components/StatusBlock/StatusBlock'
import { StatusHeader } from 'components/StatusBlock/StatusBlock.styles'
import { Text } from 'components/Text/Text.styles'
import TextNumber from 'components/TextNumber/TextNumber'
import MainTitle from 'components/Title/MainTitle'
import { Title2 } from 'components/Title/Title.styles'
import Tooltip from 'components/Tooltip/Tooltip'
import TextTooltip from 'components/TooltipContents/TextTooltip'
import { API_ROUTES } from 'constants/configs'
import { DeleteIcon, ExportIcon, PrintIcon } from 'constants/icons'
import ActionLogModal from 'features/Modals/ActionLog/ActionLogModal'
import ConfirmModal from 'features/Modals/ConfirmModal'
import CanAccess from 'features/Permissions/CanAccess'
import ShouldDisable from 'features/Permissions/ShouldDisable'
import { useFetchQuery } from 'hooks/useFetchQuery'
import useShouldReload from 'hooks/useShouldReload'
import _ from 'lodash'
import { useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Params, useParams } from 'react-router-dom'
import { RoleAction, StockStatus } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import * as yup from 'yup'
import StockTreatButton from './components/StockTreatButton'
import StockValidateButton from './components/StockValidateButton'
import DisplayStock from './forms/display/DisplayStock'
import StockEntriesForm from './forms/edditStockEntries/EditStockEntriesForm'
import StockDetailForm from './forms/EditStockForm'
import { formatStockStatus, useStockActionsNotifications } from './helpers'
import {
  ActionCellContainer,
  ActionLabel,
  AlignRight,
  GridActionsLayout,
  MarginCardLayout,
  PrintOrderSelectWrapper,
  SmallCardLayout,
  StatisticsContainer,
  TransferCard
} from './StockDetails.styles'

interface QueryParams extends Params {
  id: string
}

enum PrintOrderBy {
  ByName = 'byName',
  ByLocation = 'byLocation'
}

const printSchema = yup.object({
  orderBy: yup.mixed<PrintOrderBy>().oneOf(Object.values(PrintOrderBy))
})

interface PrintFormInputs extends yup.InferType<typeof printSchema> {}

function StockDetails() {
  const { t } = useTranslation()
  const { id } = useParams() as QueryParams
  const { data: stock, isLoading } = useGetStockById(id)
  const { downloadFile: downloadPrintFile, isLoading: isPrintFileLoading } = useDownloadFile()
  const { downloadFile: downloadExportFile, isLoading: isExportFileLoading } = useDownloadFile()
  const [printModalOpen, togglePrintModal] = useState(false)
  const [cancelModalOpen, toggleCancelModal] = useState(false)
  const [actionLogOpen, toggleActionLog] = useState(false)

  useShouldReload(id)

  const [inStockTooltip, terminatedTooltip, cancelTooltip] = useMemo(
    () => [
      <Tooltip id="inStockTooltip">
        <Trans i18nKey="tooltips.stocks.putInStock.text" components={{ br: <br /> }} />
      </Tooltip>,
      <Tooltip id="terminatedTooltip">
        <Trans i18nKey="tooltips.stocks.terminate.text" components={{ br: <br /> }} />
      </Tooltip>,
      <TextTooltip id="cancelTooltip" text={t('tooltips.stocks.cancel.text')} />
    ],
    [t]
  )

  const [createdStep, inStockStep, terminatedStep, canceledStep] = formatStockStatus(stock)
  const { cancelMutation } = useStockActionsNotifications()
  const statusHeaderRef = useRef(null)

  // Prefetch transfers from this stock, to avoid quantity reloading
  useFetchQuery([getStockTransfersQueryKey(id)], () => getStockEntriesTransfered(id, { pagination: false }))

  const { data: stockStats } = useQuery(['stockStats', `${id}`], () => getStockStats(id))

  const printOrderByOptions: Option[] = [
    {
      value: PrintOrderBy.ByName,
      label: t('page.stock.detail.action.print.orderBy.name')
    },
    {
      value: PrintOrderBy.ByLocation,
      label: t('page.stock.detail.action.print.orderBy.location')
    }
  ]

  const { control, watch } = useForm<PrintFormInputs>({
    defaultValues: {
      orderBy: PrintOrderBy.ByName
    }
  })

  const [watchPrintOrderBy] = watch(['orderBy'])

  const handlePrint = () => {
    const payload = {
      ...(watchPrintOrderBy === PrintOrderBy.ByLocation ? { orderByLocalPosition: true } : undefined)
    }
    downloadPrintFile(`/exports/picklist/from-stock/${id}?${stringify(payload)}`)
  }

  const exportStockLabel = async () => {
    await downloadExportFile(`${API_ROUTES.exports.stock}/${stock?.id}`)
  }

  return (
    <>
      <Section>
        {isLoading ? (
          <PageLoader />
        ) : stock?.['@type'] === 'StockTransfer' ? (
          <>
            <MainTitle title={t('page.stock.detail.transfers.title', { stockId: id })} />
            <TransferCard>
              <Text fontWeight="medium" color="danger">
                {t('page.stock.detail.transfers.unhautorized.title')}
              </Text>
              <Text color="danger">
                <Trans
                  i18nKey="page.stock.detail.transfers.unhautorized.text"
                  components={{
                    a: (
                      <a
                        href={`${process.env.REACT_APP_BO_V2_HOSTNAME}/lagestiondugenie/index.php?page=stock_saisie&stock_id=${stock.id}`}
                        className="external-link "
                        target="_blank"
                        rel="noreferrer"
                        //avoir accessibility error
                        children=""
                      />
                    )
                  }}
                />
              </Text>
            </TransferCard>
          </>
        ) : (
          <>
            <StatusHeader ref={statusHeaderRef}>
              <StatusBlock
                icon={oneDoodleSvg}
                title={t('common.select.stock.stockStatus.created')}
                type={createdStep?.type ?? StatusTypes.pending}
                boldText={createdStep?.date}
                lightText={createdStep?.name}
                containerRef={statusHeaderRef}
              />
              <StatusBlock
                icon={twoDoodleSvg}
                title={t('common.select.stock.stockStatus.validated')}
                type={inStockStep?.type ?? StatusTypes.pending}
                boldText={inStockStep?.date}
                lightText={inStockStep?.name}
                containerRef={statusHeaderRef}
              />
              <StatusBlock
                icon={threeDoodleSvg}
                title={t('common.select.stock.stockStatus.treated')}
                type={terminatedStep?.type ?? StatusTypes.pending}
                boldText={terminatedStep?.date}
                lightText={terminatedStep?.name}
                containerRef={statusHeaderRef}
              />
              {canceledStep && (
                <StatusBlock
                  icon={fourDoodleSvg}
                  title={t('common.select.stock.stockStatus.canceled')}
                  type={canceledStep?.type ?? StatusTypes.pending}
                  boldText={canceledStep?.date}
                  lightText={canceledStep?.name}
                  containerRef={statusHeaderRef}
                />
              )}
            </StatusHeader>
            <MainTitle title={t('page.stock.detail.title', { stockId: id })} />
            {stock && (
              <CanAccess
                permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT]}
                allowedComponent={<StockDetailForm stockId={id} stock={stock} />}
                deniedComponent={<DisplayStock stock={stock} />}
              />
            )}

            <GridActionsLayout>
              <ActionCellContainer>
                <ActionLabel>
                  <Text fontWeight="light">{t('page.stock.detail.action.logs.description')}</Text>
                </ActionLabel>
                <Button variant="white" onClick={() => toggleActionLog(true)}>
                  {t('page.stock.detail.action.logs.label')}
                </Button>
              </ActionCellContainer>

              <ActionCellContainer>
                <ActionLabel>
                  <Text fontWeight="light">{t('page.stock.detail.action.print.description')}</Text>
                </ActionLabel>
                <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STOCKS_PRINT]}>
                  <Button
                    onClick={() => togglePrintModal(true)}
                    disabled={stock?.status === StockStatus.Canceled || !stock?.entries?.length}
                    icon={PrintIcon}
                    isLoading={isPrintFileLoading}
                  >
                    {t('page.stock.detail.action.print.label')}
                  </Button>
                </ShouldDisable>
              </ActionCellContainer>

              <ActionCellContainer>
                <ActionLabel>
                  <Text fontWeight="light">{t('page.stock.detail.action.export.description')}</Text>
                </ActionLabel>
                <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STOCKS_PRINT]}>
                  <Button icon={ExportIcon} isLoading={isExportFileLoading} onClick={() => exportStockLabel()}>
                    {t('page.stock.detail.action.export.label')}
                  </Button>
                </ShouldDisable>
              </ActionCellContainer>

              <ActionCellContainer>
                <ActionLabel>
                  <Text fontWeight="light">{t('page.stock.detail.action.stock.description')}</Text>
                  {inStockTooltip}
                </ActionLabel>
                <StockValidateButton stock={stock} />
              </ActionCellContainer>

              <ActionCellContainer>
                <ActionLabel>
                  <Text fontWeight="light">{t('page.stock.detail.action.terminated.description')}</Text>
                  {terminatedTooltip}
                </ActionLabel>
                <StockTreatButton stock={stock} />
              </ActionCellContainer>

              <ActionCellContainer>
                <ActionLabel>
                  <Text fontWeight="light">{t('page.stock.detail.action.canceled.description')}</Text>
                  {cancelTooltip}
                </ActionLabel>
                <ShouldDisable permissions={[RoleAction.ROLE_ACTION_STOCK_EDIT_ACTION]}>
                  <Button
                    variant="danger"
                    icon={DeleteIcon}
                    disabled={!stock?.cancelable || stock?.status === StockStatus.Canceled}
                    onClick={() => toggleCancelModal(true)}
                  >
                    {t('page.stock.detail.action.canceled.label')}
                  </Button>
                </ShouldDisable>
              </ActionCellContainer>
            </GridActionsLayout>
            <MarginCardLayout>
              <Title2>
                <RowContainer>
                  <Text size="1.6rem" fontWeight="bold">
                    {t('page.stock.detail.products.quantity', {
                      count: _.sumBy(stock?.entries, 'quantity')
                    })}
                  </Text>
                  <Text size="1.6rem" fontWeight="bold">
                    {t('page.stock.detail.products.count', {
                      count:
                        _.chain(stock?.entries)
                          .map((entry) => entry?.product?.id)
                          .uniq()
                          .value().length ?? 0
                    })}
                  </Text>
                </RowContainer>
              </Title2>

              <StockEntriesForm stock={stock!} />
            </MarginCardLayout>
            {stockStats && stock?.status && [StockStatus.Validated, StockStatus.Treated].includes(stock.status) && (
              <SmallCardLayout>
                <Title2>{t('page.stock.detail.products.statistics.title')}</Title2>
                <StatisticsContainer>
                  <Text fontWeight="light" color="secondary">
                    {t('page.stock.detail.products.statistics.expected')}
                  </Text>
                  <TextNumber
                    value={stockStats.potentialSells}
                    suffix=" €"
                    decimalScale={2}
                    ContainerComponent={AlignRight}
                    fontWeight="light"
                    color="secondary"
                  />
                  <Text fontWeight="light" color="secondary">
                    {t('page.stock.detail.products.statistics.actual')}
                  </Text>

                  <TextNumber
                    value={stockStats.realisedSells}
                    suffix=" €"
                    decimalScale={2}
                    ContainerComponent={AlignRight}
                    fontWeight="light"
                    color="secondary"
                    placeholder="-"
                  />
                  <Text fontWeight="light" color="secondary">
                    {t('page.stock.detail.products.statistics.buyPrice')}
                  </Text>
                  <TextNumber
                    value={stockStats.remainingSellsBuyPrice}
                    suffix=" €"
                    decimalScale={2}
                    ContainerComponent={AlignRight}
                    fontWeight="light"
                    color="secondary"
                  />
                  <Text fontWeight="light" color="secondary">
                    {t('page.stock.detail.products.statistics.sellPrice')}
                  </Text>
                  <TextNumber
                    value={stockStats.remainingSellsSellPrice}
                    suffix=" €"
                    decimalScale={2}
                    ContainerComponent={AlignRight}
                    fontWeight="light"
                    color="secondary"
                  />
                  <Text fontWeight="light" color="secondary">
                    {t('page.stock.detail.products.statistics.refund')}
                  </Text>
                  <TextNumber
                    value={stockStats.reimbursementPercentage}
                    suffix=" %"
                    decimalScale={2}
                    ContainerComponent={AlignRight}
                    fontWeight="light"
                    color="secondary"
                    placeholder="-"
                  />
                </StatisticsContainer>
              </SmallCardLayout>
            )}
          </>
        )}
        {stock?.['@type'] !== 'StockTransfer' && <BottomMessage text={t('common.requiredLabel')} />}
      </Section>
      <ConfirmModal
        open={cancelModalOpen}
        onClose={() => toggleCancelModal(false)}
        callback={() => cancelMutation.mutate(stock?.id!)}
        title={t('page.stock.detail.action.canceled.modalTitle', { stockId: id })}
        confirmLabel={t('page.stock.detail.action.canceled.modalConfirm')}
      />
      <ConfirmModal
        open={printModalOpen}
        onClose={() => togglePrintModal(false)}
        callback={handlePrint}
        title={t('page.stock.detail.action.print.modalTitle', { stockId: id })}
        confirmBtnProps={{
          icon: PrintIcon,
          variant: 'default'
        }}
        confirmLabel={t('common.label.print')}
      >
        <PrintOrderSelectWrapper>
          <Select
            control={control}
            id="orderBy"
            options={printOrderByOptions}
            label={t('page.stock.detail.action.print.orderBy.label')}
          />
        </PrintOrderSelectWrapper>
      </ConfirmModal>
      <ActionLogModal open={actionLogOpen} onClose={() => toggleActionLog(false)} target={stock?.['@id']!} />
    </>
  )
}

export default StockDetails
