import { AxiosResponse } from 'axios'
import { API_ROUTES, API_ROUTES_BASES } from 'constants/configs'
import { useMutation } from 'hooks/useAxiosMutation'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { Collaborator } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

/* -------------------------------------------------------------------------- */
/*                                    FETCH                                   */
/* -------------------------------------------------------------------------- */

export type FetchCollaboratorsQueryParams = Api.GetCollaborators.RequestQuery
export const getCollaboratorsUri = (params: FetchCollaboratorsQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.collaborators.root, queryString].join('?')
}

type FetchCollaboratorsResponse = Api.GetCollaborators.ResponseBody
export type FetchPaginatedCollaboratorsResponse = FetchCollaboratorsResponse['hydra:member'][0]
export const usePaginatedCollaborators = (
  queryKey,
  query: string,
  options?: PaginatedHookOptions<FetchPaginatedCollaboratorsResponse>
) => usePaginatedQuery<FetchPaginatedCollaboratorsResponse>(queryKey, query, options)

export const fetchCollaborators = async (body: FetchCollaboratorsQueryParams): Promise<Collaborator[]> => {
  let res = await playInApi.get<FetchCollaboratorsResponse>(getCollaboratorsUri(body))
  return res.data['hydra:member']
}

export const collaboratorByIdQueryKey = (id?: string | number) => `collaborator-${id}`

type FetchCollaboratorResponse = Api.GetCollaboratorsId.ResponseBody
export const fetchCollaboratorById = async (id: string) => {
  const res = await playInApi.get<FetchCollaboratorResponse>(`${API_ROUTES.collaborators.root}/${id}`)

  return res.data
}

export const useCollaboratorById = (id: string) => {
  const navigate = useNavigate()
  return useQuery<FetchCollaboratorResponse, AxiosResponse>(
    collaboratorByIdQueryKey(id),
    () => fetchCollaboratorById(id!),
    {
      enabled: !!id,
      onError: (error) => {
        // replace: true, we prevent going back to the page that respond 40X
        if (error) {
          //to avoid 404 on NS_BINDING_ABORTED error on Firefox
          navigate(`/${error?.status === 403 ? 403 : 404}`, { replace: true })
        }
      }
    }
  )
}

export type GetCollaboratorsResponse = Api.GetCollaborators.ResponseBody
type GetCollaboratorsQueryParams = Api.GetCollaborators.RequestQuery
export const getCollaborator = async (params: GetCollaboratorsQueryParams) => {
  const response = await playInApi.get<GetCollaboratorsResponse>(API_ROUTES_BASES.collaborators, {
    params
  })

  return response.data['hydra:member']
}

/* -------------------------------------------------------------------------- */
/*                                    POST                                    */
/* -------------------------------------------------------------------------- */

export type CreateCollaboratorsBody = Api.PostCollaborators.RequestBody
export const createCollaborator = async (body: CreateCollaboratorsBody) => {
  const res = await playInApi.post<CreateCollaboratorsBody, AxiosResponse<Collaborator>>(
    API_ROUTES.collaborators.root,
    body
  )

  return res.data
}

export const resetCollaboratorPassword = async (id: string) =>
  await playInApi.post(`${API_ROUTES.collaborators.reset}/${id}`, {})

export const deactivateCollaborator = async (id: number) => {
  const res = await playInApi.post<{}, AxiosResponse<Collaborator>>(`${API_ROUTES.collaborators.deactivate}/${id}`, {})
  return res.data
}

export const activateCollaborator = async (id: number) => {
  const res = await playInApi.post<{}, AxiosResponse<Collaborator>>(`${API_ROUTES.collaborators.activate}/${id}`, {})
  return res.data
}

/* -------------------------------------------------------------------------- */
/*                                    PATCH                                   */
/* -------------------------------------------------------------------------- */

export type PatchCollaboratorBody = Api.PatchCollaboratorsId.RequestBody
export const patchCollaborator = async ({ id, body }: { id: string; body: PatchCollaboratorBody }) => {
  const res = await playInApi.patch<PatchCollaboratorBody, AxiosResponse<Collaborator>>(
    `${API_ROUTES.collaborators.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export const useMutateCollaborator = (id: number, callback?: (collaborator?: Collaborator) => void) => {
  const queryClient = useQueryClient()
  return useMutation((body: PatchCollaboratorBody) => patchCollaborator({ id: `${id}`, body }), {
    onSuccess: (updatedCollaborator) => {
      queryClient.setQueryData(collaboratorByIdQueryKey(id), updatedCollaborator)
      if (callback) callback(updatedCollaborator)
    }
  })
}

export type PatchCollaboratorsUpdatePasswordBody = Api.PatchCollaboratorsUpdatePassword.RequestBody
export const patchCollaboratorPassword = async ({ body }: { body: PatchCollaboratorsUpdatePasswordBody }) => {
  const res = await playInApi.patch<PatchCollaboratorsUpdatePasswordBody, AxiosResponse<Collaborator>>(
    `${API_ROUTES.collaborators.updatePassword}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export const useMutateCollaboratorUpdatePassword = () => {
  return useMutation((body: PatchCollaboratorsUpdatePasswordBody) => patchCollaboratorPassword({ body }))
}
