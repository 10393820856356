import { AxiosResponse } from 'axios'
import { API_ROUTES, WAREHOUSE_STORE_ID } from 'constants/configs'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { StoreDetails, StoreExceptionalHours, StoreHours, StoreItem } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

export const storeByIdQueryKey = (id: number | string) => ['store', `${id}`]

type FetchStoresResponse = Api.GetStores.ResponseBody
export type FetchStoresQueryParams = Api.GetStores.RequestQuery

const getStoresUri = (params?: FetchStoresQueryParams) => {
  const qs = stringify(params ?? {})
  return [API_ROUTES.stores.root, qs].join('?')
}

export const fetchStores = async (params?: FetchStoresQueryParams) => {
  const res = await playInApi.get<FetchStoresResponse>(getStoresUri(params))

  return res.data['hydra:member']
}

export type PaginatedStoreItem = Api.GetStores.ResponseBody['hydra:member'][0]

type FetchStoreItem = Api.GetStoresId.ResponseBody
export const getStoreById = async (id: string | number): Promise<StoreItem> => {
  const res = await playInApi.get<FetchStoreItem>(`${API_ROUTES.stores.root}/${id}`)
  return res.data
}

export type PostStoreBody = Api.PostStores.RequestBody
export const postStore = async (body: PostStoreBody) => {
  const res = await playInApi.post<PostStoreBody, AxiosResponse<StoreDetails>>(API_ROUTES.stores.root, body)
  return res.data
}

export type PatchStoreBody = Api.PatchStoresId.RequestBody
export const patchStore = async (id: number, body: PatchStoreBody) => {
  const res = await playInApi.patch<PatchStoreBody, AxiosResponse<StoreDetails>>(
    `${API_ROUTES.stores.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export const getWarehouseQueryKey = 'warehouse'
export const useGetWarehouse = () => {
  return useQuery([getWarehouseQueryKey], () =>
    getStoreById(WAREHOUSE_STORE_ID.replace(`/${API_ROUTES.stores.root}/`, ''))
  )
}

export const useFetchStores = () => useQuery('getStores', () => fetchStores({ 'order[shortName]': 'asc' }))

export const hoursByStoreQueryKey = (store?: number) => ['hours', { store }]

type FetchHoursParams = Api.GetStoreHours.RequestQuery
const getStoreHoursUri = (params: FetchHoursParams) => {
  return [API_ROUTES.storeHours.root, stringify(params)].join('?')
}
export const fetchStoreHours = async (params: FetchHoursParams) => {
  const res = await playInApi.get<FetchHoursParams, AxiosResponse<StoreHours>>(getStoreHoursUri(params))
  return res.data['hydra:member']
}

export type PostStoreHourBody = Api.PostStoreHours.RequestBody
export const postStoreHour = async (body: PostStoreHourBody) => {
  const res = await playInApi.post<PostStoreHourBody, AxiosResponse<StoreHours>>(API_ROUTES.storeHours.root, body)
  return res.data
}

export type PatchStoreHourBody = Api.PatchStoreHoursId.RequestBody
export const patchStoreHour = async (id: number, body: PatchStoreHourBody) => {
  const res = await playInApi.patch<PatchStoreHourBody, AxiosResponse<StoreHours>>(
    `${API_ROUTES.storeHours.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )

  return res.data
}

export const useGetStoreById = (id: string) => {
  const navigate = useNavigate()
  return useQuery<FetchStoreItem, AxiosResponse>(storeByIdQueryKey(id), () => getStoreById(id), {
    onError: (error) => {
      // replace: true, we prevent going back to the page that respond 40X
      if (error) {
        //to avoid 404 on NS_BINDING_ABORTED error on Firefox
        navigate(`/${error?.status === 403 ? 403 : 404}`, { replace: true })
      }
    }
  })
}
export const deleteStoreHour = async (id: number | undefined) => {
  const res = await playInApi.delete(`${API_ROUTES.storeHours.root}/${id}`)
  return res.data
}

export type PostStoreExceptionalHoursBody = Api.PostStoreExceptionalHours.RequestBody
export const postStoreExceptionalHours = async (body: PostStoreExceptionalHoursBody) => {
  const res = await playInApi.post<PostStoreExceptionalHoursBody, AxiosResponse<StoreExceptionalHours>>(
    API_ROUTES.storeExceptionalHours.root,
    body
  )
  return res.data
}

export type DeleteStoreExceptionalHours = Api.DeleteStoreExceptionalHoursId.RequestBody
export const deleteStoreExceptionalHours = async (id: number | undefined) => {
  const res = await playInApi.delete<DeleteStoreExceptionalHours, AxiosResponse<StoreExceptionalHours>>(
    `${API_ROUTES.storeExceptionalHours.root}/${id}`
  )

  return res.data
}
