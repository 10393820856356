import { AxiosResponse } from 'axios'
import { API_ROUTES, API_ROUTES_BASES } from 'constants/configs'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { StoreEvent, StoreEventCustomer, StoreEventOrder } from 'types/entities'
import { Api } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

export const eventByIdQueryKey = (id: string | number) => ['eventById', `${id}`]

type GetEventByIdResponse = Api.GetStoreEventsId.ResponseBody
const getEventById = async (id: string) => {
  const response = await playInApi.get<GetEventByIdResponse>(`${API_ROUTES.storeEvents.root}/${id}`)
  return response.data
}

export type GetStoreEventRangesResponse = Api.GetStoreEventRanges.ResponseBody
type GetStoreEvenetRangesQueryParams = Api.GetStoreEventRanges.RequestQuery
export const getStoreEventRanges = async (params: GetStoreEvenetRangesQueryParams) => {
  const response = await playInApi.get<GetStoreEventRangesResponse>(API_ROUTES_BASES.storeEventRanges, {
    params
  })

  return response.data['hydra:member']
}

export type GetStoreEventFormatsResponse = Api.GetStoreEventFormats.ResponseBody
type GetStoreEventFormatsQueryParams = Api.GetStoreEventFormats.RequestQuery
export const getStoreEventFormats = async (params: GetStoreEventFormatsQueryParams) => {
  const response = await playInApi.get<GetStoreEventFormatsResponse>(API_ROUTES_BASES.storeEventFormats, {
    params
  })

  return response.data['hydra:member']
}

export type GetStoreEventsQueryParams = Api.GetStoreEvents.RequestQuery
export const getStoreEventsUri = (params: GetStoreEventsQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.storeEvents.root, queryString].join('?')
}

export type PostStoreEventBody = Api.PostStoreEvents.RequestBody
export const postStoreEvent = async (body: PostStoreEventBody) => {
  const res = await playInApi.post<PostStoreEventBody, AxiosResponse<StoreEvent>>(API_ROUTES.storeEvents.root, body)
  return res.data
}

export type PatchStoreEventBody = Api.PatchStoreEventsId.RequestBody
export const patchStoreEvent = async (body: PatchStoreEventBody, id: number) => {
  const res = await playInApi.patch<PatchStoreEventBody, AxiosResponse<StoreEvent>>(
    `${API_ROUTES.storeEvents.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export type PostStoreEventCustomerBody = Api.PostStoreEventCustomers.RequestBody
export type PostStoreEventCustomerResponse = Api.PostStoreEventCustomers.ResponseBody
export const postStoreEventCustomer = async (body: PostStoreEventCustomerBody) => {
  const res = await playInApi.post<PostStoreEventCustomerBody, AxiosResponse<PostStoreEventCustomerResponse>>(
    API_ROUTES.storeEventCustomers.root,
    body
  )
  return res.data
}

export type PatchStoreEventCustomerBody = Api.PatchStoreEventCustomersId.RequestBody
export const patchStoreEventCustomer = async (body: PatchStoreEventCustomerBody, id: number) => {
  const res = await playInApi.patch<PatchStoreEventCustomerBody, AxiosResponse<StoreEventCustomer>>(
    `${API_ROUTES.storeEventCustomers.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export type PostCancelStoreEventCustomerBody = Api.PostStoreEventCustomersCancelId.RequestBody
export type PostCancelStoreEventCustomerParams = Api.PostStoreEventCustomersCancelId.RequestQuery

const getRefundStoreEventCustomerIri = (id: number, params: PostCancelStoreEventCustomerParams) => {
  const qs = stringify(params)
  return [`${API_ROUTES.storeEventCustomers.cancel}/${id}`, qs].join('?')
}
export const postCancelStoreEventCustomer = async ({
  id,
  body,
  params
}: {
  id: number
  body: PostCancelStoreEventCustomerBody
  params: PostCancelStoreEventCustomerParams
}) => {
  const res = await playInApi.post<PostCancelStoreEventCustomerBody, AxiosResponse<StoreEventCustomer>>(
    getRefundStoreEventCustomerIri(id, params),
    body
  )

  return res.data
}

export type PatchStoreEventOrderBody = Api.PatchStoreEventOrdersId.RequestBody
export const patchStoreEventOrder = async (body: PatchStoreEventOrderBody, id: number) => {
  const res = await playInApi.patch<PatchStoreEventOrderBody, AxiosResponse<StoreEventOrder>>(
    `${API_ROUTES.storeEventOrders.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )
  return res.data
}

export const closeStoreEvent = async (id: number) => {
  const res = await playInApi.post<{}, AxiosResponse<PostStoreEventCustomerResponse>>(
    `${API_ROUTES.storeEvents.close}/${id}`,
    {}
  )
  return res.data
}

export type DuplicateStoreEventBody = Api.PostStoreEventsCloneOfId.RequestBody
export const duplicateStoreEvent = async (id: number, body: DuplicateStoreEventBody) => {
  const res = await playInApi.post<DuplicateStoreEventBody, AxiosResponse<StoreEvent>>(
    `${API_ROUTES.storeEvents.clone}/${id}`,
    body
  )
  return res.data
}

export const cancelStoreEvent = async (id: number) => {
  const res = await playInApi.post(`${API_ROUTES.storeEvents.cancel}/${id}`, {})
  return res.data
}

export type EventDeliverVouchersBody = Api.PostStoreEventsDeliverVouchersId.RequestBody
export const deliverVouchers = async (body: EventDeliverVouchersBody, id: number) => {
  const res = await playInApi.post<EventDeliverVouchersBody, AxiosResponse<StoreEvent>>(
    `${API_ROUTES.storeEvents.deliverVouchers}/${id}`,
    body
  )
  return res.data
}

export const useGetStoreEventById = (id: string) => {
  const navigate = useNavigate()
  return useQuery<GetEventByIdResponse, AxiosResponse>(eventByIdQueryKey(id), () => getEventById(id), {
    onError: (error) => {
      // replace: true, we prevent going back to the page that respond 40X
      if (error) {
        //to avoid 404 on NS_BINDING_ABORTED error on Firefox
        navigate(`/${error?.status === 403 ? 403 : 404}`, { replace: true })
      }
    }
  })
}
