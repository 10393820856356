import { useCustomerById } from 'api/customers'
import BottomMessage from 'components/BottomMessage/BottomMesssage'
import { PageLoader } from 'components/Loader/Loader'
import { Section } from 'components/Section/Section.styles'
import MainTitle from 'components/Title/MainTitle'
import useShouldReload from 'hooks/useShouldReload'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import OrderSummary from './components/OrderSummary'
import ResetPassword from './components/ResetPassword'
import { InformationsLayout } from './CustomerDetails.style'
import AccountForm from './forms/AccountForm'
import CustomerForm from './forms/CustomerForm'
import FidelityList from './list/FidelityList'
import FutureEventList from './list/FutureEventList'
import LitigationList from './list/LitigationList'
import OrderList from './list/OrderList'
import SellList from './list/SellList'
import VoucherList from './list/VoucherList'

function CustomerDetails() {
  const { id } = useParams()
  const { data: customer } = useCustomerById(id!)
  const { t } = useTranslation()

  useShouldReload(id)

  return (
    <Section>
      {customer ? (
        <>
          <MainTitle title={customer.fullName ?? ''} />
          <InformationsLayout>
            <ResetPassword customer={customer} />
            <OrderSummary />
            <AccountForm customer={customer} />
            <CustomerForm customer={customer} />
          </InformationsLayout>
          <OrderList customer={customer} />
          <SellList />
          <FidelityList customer={customer} />
          <VoucherList customer={customer} />
          <LitigationList customer={customer} />
          <FutureEventList customer={customer} />
        </>
      ) : (
        <PageLoader />
      )}
      <BottomMessage text={t('common.requiredLabel')} />
    </Section>
  )
}

export default CustomerDetails
