import { AxiosResponse } from 'axios'
import { API_ROUTES } from 'constants/configs'
import { PaginatedHookOptions, usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { QueryKey, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { SealedProduct } from 'types/entities'
import { Api, ProductStoreJsonldProductRead } from 'types/playInApiInterfaces'
import { stringify } from 'utils/queryParams'
import playInApi from './basePlayInApi'

export type ProductStoreFromSealedProductCollection = ProductStoreJsonldProductRead
export type FetchSealedProductsQueryParams = Api.GetSealedProducts.RequestQuery
export const getSealedProductsUri = (params: FetchSealedProductsQueryParams): string => {
  const queryString = stringify(params)
  return [API_ROUTES.sealedProducts.root, queryString].join('?')
}

type FetchSealedProdutsResponse = Api.GetSealedProducts.ResponseBody['hydra:member'][0]
export const useSealedProductsPaginated = (
  queryKey: QueryKey,
  query: string,
  options?: PaginatedHookOptions<FetchSealedProdutsResponse>
) => usePaginatedQuery<FetchSealedProdutsResponse>(queryKey, query, options)

type SealedProductItem = Api.GetSealedProductsId.ResponseBody
const getSealedProductById = async (id: string): Promise<SealedProduct> => {
  const response = await playInApi.get<SealedProductItem>(`${API_ROUTES.sealedProducts.root}/${id}`)

  return response.data
}

export const fetchSealedProducts = async (query: string): Promise<FetchSealedProdutsResponse[]> => {
  const res = await playInApi.get<FetchSealedProdutsResponse>(query)
  return res.data['hydra:member']
}

export const useGetSealedProductById = (id: string) => {
  const navigate = useNavigate()
  return useQuery<SealedProduct, AxiosResponse>(['sealedProductById', id], () => getSealedProductById(id), {
    onError: (error) => {
      // replace: true, we prevent going back to the page that respond 40X
      if (error) {
        //to avoid 404 on NS_BINDING_ABORTED error on Firefox
        navigate(`/${error?.status === 403 ? 403 : 404}`, { replace: true })
      }
    }
  })
}

export type PatchSealedProductBody = Api.PatchSealedProductsId.RequestBody
export const patchSealedProduct = async (id: string, body: PatchSealedProductBody) => {
  const response = await playInApi.patch<PatchSealedProductBody, AxiosResponse<SealedProductItem>>(
    `${API_ROUTES.sealedProducts.root}/${id}`,
    body,
    {
      headers: {
        'Content-Type': 'application/merge-patch+json'
      }
    }
  )

  return response.data
}
