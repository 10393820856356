import usePrevious from 'hooks/usePrevious'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'

const useShouldReload = (id) => {
  const navigate = useNavigate()
  const prevId = usePrevious(id)
  useEffect(() => {
    if (prevId && prevId !== id) {
      navigate(0)
    }
  }, [id, navigate, prevId])
}

export default useShouldReload
